body {
    overflow-x: hidden;
  }
  
  .transition-fade {
    opacity: 1;
    transition: 500ms;
    transform: translateX(0);
    transform-origin: left;
  }
  
  html.is-animating .transition-fade {
    opacity: 1;
    transform: translateX(100%);
  }
  
  html.is-leaving .transition-fade {
    opacity: 0;
    transform: translateX(0);
  }
  
  .transition-swipe {
    transition: 500ms;
    transform: translateY(0);
  }
  
  html.is-animating .transition-swipe {
    transform: translateY(100px);
  }